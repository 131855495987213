<template>
  <v-container class="pa-0" data-id="note_header">
    <df-page-header
      :title="$t('Note.notes')"
      :subtitle="$t('Note.field_notebook')"
    >
      <template #actions>
        <v-btn
          ref="createNote"
          v-if="enabledFarm && hasPermission($p.note.create)"
          class="white--text text-none button-register-confirm"
          text
          @click="openFormRegisterNote"
        >
          <span class="mr-2">
            {{ $t('Note.new_note') }}
          </span>
        </v-btn>
      </template>
    </df-page-header>

    <modal-note
      v-if="noteRegisterOpen"
      :dialog="noteRegisterOpen"
      @close="closeModalNote"
    />
  </v-container>
</template>

<script>
import DfPageHeader from '@/lib/components/Header/DfPageHeader.vue'
import PermissionMixin from '@/components/Permission/PermissionMixin.vue'
import ModalNote from '@/modules/notes/components/Modals/ModalNote.vue'
import { logEvent, events } from '@/services/analytics'
import { mapGetters } from 'vuex'

export default {
  name: 'NotesHeader',

  mixins: [PermissionMixin],

  components: {
    DfPageHeader,
    ModalNote,
  },

  props: {
    nextRouteParams: {
      default: () => {},
      type: Object,
    },
    previousPath: {
      default: '',
      type: String,
    },
  },
  data() {
    return {
      noteRegisterOpen: false,
    }
  },
  computed: {
    ...mapGetters('farms', ['enabledFarm']),
  },
  mounted() {
    this.$nextTick(function () {
      if (
        this.previousPath === '/my_farm' &&
        this.nextRouteParams?.query?.open_modal
      ) {
        this.callCreateNote()
      }
    })
  },
  methods: {
    closeModalNote() {
      this.noteRegisterOpen = false
    },
    callCreateNote() {
      const button = this.$refs.createNote
      if (button) {
        this.openFormRegisterNote()
      }
    },
    openFormRegisterNote() {
      logEvent(events.notesModule.newNote)
      this.noteRegisterOpen = true
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-plus {
  font-size: 0.75rem;
  font-weight: 900;
}
.button-register-confirm {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0;
  background-color: #39af49 !important;
  border-color: #39af49;
}
</style>
