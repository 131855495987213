<template>
  <v-menu
    v-model="toggle"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="formattedValue"
        :label="label"
        readonly
        :solo="solo"
        :dense="dense"
        :outlined="outlined"
        :error-messages="errorMessages"
        :background-color="backgroundColor"
        :placeholder="placeholder"
        :color="color"
        v-bind="attrs"
        v-on="on"
      >
        <template #append>
          <font-awesome-icon color="#788476" icon="calendar" />
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="v"
      :color="color"
      @input="changeValue"
      :min="min"
      :max="max"
      show-adjacent-months
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      toggle: false,
      v: '',
    }
  },
  props: {
    label: {
      type: String,
      default: '',
    },

    value: {
      type: String,
      default: '',
    },

    solo: {
      type: Boolean,
      default: false,
    },

    dense: {
      type: Boolean,
      default: false,
    },

    outlined: {
      type: Boolean,
      default: false,
    },

    backgroundColor: {
      type: String,
      default: '',
    },

    errorMessages: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    min: {
      type: String,
      default: '',
    },

    max: {
      type: String,
      default: '',
    },

    color: {
      type: String,
      default: '',
    },
  },

  computed: {
    formattedValue() {
      return this.v ? this.v.formatDate() : ''
    },
  },

  watch: {
    value(newValue) {
      this.v = newValue
    },
  },

  methods: {
    changeValue() {
      this.$emit('input', this.v)
      this.$emit('change', this.v)
      this.toggle = false
    },
  },
}
</script>

<style scoped></style>
