<template>
  <v-row no-gutters justify="start">
    <v-col
      v-for="(image, index) in displayedImages"
      :key="index"
      cols="4"
      sm="3"
      md="2"
      class="d-flex pa-2"
    >
      <v-card flat tile class="d-flex" @click="openModal(index)">
        <v-img
          width="100"
          height="100"
          :src="image.storage_link"
          aspect-ratio="1"
          class="grey lighten-2 image-border"
        >
          <template #placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <div v-if="showMask && index === 5" class="mask">
          + {{ remainingCount }}
        </div>
      </v-card>
    </v-col>

    <v-dialog v-model="dialog" :width="carouselWidth" hide-overlay>
      <v-card>
        <v-carousel
          v-if="images.length > 0"
          :value="currentIndex"
          dark
          @change="updateCurrentIndex"
          hide-delimiters
        >
          <v-carousel-item v-for="(item, i) in images" :key="i">
            <v-container fluid fill-height>
              <v-layout align-center justify-center>
                <v-flex>
                  <v-img
                    :src="item.storage_link"
                    :aspect-ratio="getAspectRatio(item.storage_link)"
                    position="center center !important"
                    contain
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
      displayedImages: [],
      remainingCount: 0,

      showMask: false,

      currentIndex: 0,

      carouselWidth: 800,
      carouselHeight: null,
    }
  },

  mounted() {
    this.displayedImages = this.images.slice(0, 6)
    if (this.images.length > 6) {
      this.showMask = true
      this.remainingCount = this.images.length - 6
    }

    window.addEventListener('resize', this.calculateCarouselHeight)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.calculateCarouselHeight)
  },

  methods: {
    openModal(index) {
      this.currentIndex = index
      this.dialog = true
    },

    updateCurrentIndex(newIndex) {
      this.currentIndex = newIndex
    },

    calculateCarouselHeight() {
      const aspectRatio = 16 / 9
      this.carouselHeight = this.carouselWidth / aspectRatio
    },

    getAspectRatio(imgSrc) {
      const img = new Image()
      img.src = imgSrc
      const width = img.width
      const height = img.height
      return width > height ? width / height : height / width
    },
  },
}
</script>

<style>
.image-border {
  border-radius: 8px !important;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  z-index: 2;
  border-radius: 8px !important;
}
</style>
